import React, {useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {CourseSection, CreateCoursePopup, DeleteCourseSection, EditCoursePopup} from '../components/course'
import {getCourses} from '../slices/courseSlice'
import styled from 'styled-components'

const CoursePage = () => {
  const { courses} = useSelector((store) => store.course);
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getCourses())
  }, [dispatch]);

  return (
    <Wrapper>
        <CourseSection/>
        <CreateCoursePopup/>
        <EditCoursePopup/>
        <DeleteCourseSection/>
    </Wrapper>
  )
}

const Wrapper = styled.div`
    width: 100vw;
    padding-bottom: 40em;

    @media only screen and (min-width: 992px) {
    padding-bottom: 50em;
  }
`

export default CoursePage