import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import Cookies from 'js-cookie'
import {URL} from '../paths/url'
import { getProfile } from './userSlice'

const initialState = {
    loading: false,
    // password visibility 

    isLoginPasswordVisible: false,
    isSignupPasswordVisible: false,

    // login 
    loginInputs: {
        email: '',
        password: '',
    },
    loginLoad: false,
    loginError: false,

    // signup 
    signupInputs: {
        firstname: '',
        lastname: '',
        email: '',
        password: ''
    },
    signupLoad: false,
    signupError: false,

    // reset-password 
    resetPasswordEmail: '',
    resetPasswordLoad: false,
    resetPasswordError: false,
    resetPasswordSent: false,
    passwordResetMessage: '',

    // change password 
    changePasswordInputs: {
        newPassword: '',
        confirmPassword: ''
    },
    changePasswordLoad: false,
    changePasswordError: false,

    // logout 
    logoutLoad: false,
    logoutError: false,
    logoutErrorMsg: '',

    
    // error message 
    errorMessage: '',

    // user
    user: [],
    isAuthenticated: false,
    token: '',
    userCookie: Cookies.get('token') && true,
}

export const login = createAsyncThunk(
    'actions/login',
    async (payload, thunkAPI) => {
      const { email, password } = payload
      try {
        const resp = await axios.post(
          `${URL}/api/v1/auth/login`,
          {
            email,
            password,
          },
          {
            withCredentials: true,
          }
        )
        window.location.href = '/'
        thunkAPI.dispatch(getProfile())
        return { response: resp, status: 'success' }
      } catch (error) {
        return {
          response: error.response.data,
          status: 'error',
          code: error.response.status,
        }
      }
    }
  )

  export const signup = createAsyncThunk(
    'actions/signup',
    async (payload, thunkAPI) => {
      const { firstname, lastname, email, password} = payload
      try {
        const resp = await axios.post(
          `${URL}/api/v1/auth/signup`,
          {
            firstname,
            lastname,
            email,
            password,
          },
          {
            withCredentials: true,
          }
        )
        window.location.href = '/'
        thunkAPI.dispatch(getProfile())
        return { response: resp, status: 'success' }
      } catch (error) {
        return {
          response: error.response.data,
          status: 'error',
          code: error.response.status,
        }
      }
    }
  )

  export const logout = createAsyncThunk(
    'actions/logout',
    async (payload, thunkAPI) => {
      try {
        const resp = await axios.post(
          `${URL}/api/v1/auth/logout`,
          {
            withCredentials: true,
          }
        )
        window.location.href = '/'
        thunkAPI.dispatch(getProfile())
        return { response: resp, status: 'success' }
      } catch (error) {
        return {
          response: error.response.data,
          status: 'error',
          code: error.response.status,
        }
      }
    }
  )

  export const resetPassword = createAsyncThunk(
    'actions/resetPassword',
    async (payload, thunkAPI) => {
      const { email } = payload
      try {
        const resp = await axios.post(
          `${URL}/api/v1/auth/forgot-password`,
          {
            email,
          },
          {
            withCredentials: true,
          }
        )
        return { response: resp, status: 'success' }
      } catch (error) {
        return {
          response: error.response.data,
          status: 'error',
          code: error.response.status,
        }
      }
    }
  )

  export const changePassword = createAsyncThunk(
    'actions/changePassword',
    async (payload, thunkAPI) => {
      const {token, newPassword, confirmPassword} = payload
      try {
        const resp = await axios.patch(
          `${URL}/api/v1/auth/reset-password`,
          {
            token, newPassword, confirmPassword
          },
          {
            withCredentials: true,
          }
        )
        window.location.href = '/'
        thunkAPI.dispatch(getProfile())
        return { response: resp, status: 'success' }
      } catch (error) {
        return {
          response: error.response.data,
          status: 'error',
          code: error.response.status,
        }
      }
    }
  )

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        loadingState: (state, action) => {
            state.loading = true
        },
        fillAuthInputs: (state, action) => {
            switch (action.payload.type) {
                case 'login':
                    const {email, password} = action.payload
                    state.loginInputs.email = email
                    state.loginInputs.password = password
                    break;
                case 'signup':
                    const {email: userEmail, password: userPassword, firstname, lastname} = action.payload
                    state.signupInputs.email = userEmail
                    state.signupInputs.firstname = firstname
                    state.signupInputs.lastname = lastname
                    state.signupInputs.password = userPassword
                    break;
                case 'reset-password':
                    state.resetPasswordEmail = action.payload.resetPasswordEmail
                    break;
                case 'change-password':
                    const {newPassword, confirmPassword} = action.payload
                    state.changePasswordInputs.newPassword = newPassword
                    state.changePasswordInputs.confirmPassword = confirmPassword
                    break;
                default:
                    break;
            }
          },
      
    },
    extraReducers(builder) {
        builder
        // login
        .addCase(login.pending, (state, action) => {
            state.loginLoad = true
            state.loginError = false
          })
          .addCase(login.fulfilled, (state, action) => {
            state.loginLoad = true
            const { status, code, response } = action.payload
            if (code === 500) {
              state.loginLoad = false
              state.loginError = true
              state.errorMessage = `Can't login due to network`

            } else if (status === 'success') {
              state.loginLoad = false
              state.loginError = false

            } else {
              state.loginLoad = false
              state.loginError = true
              state.errorMessage = response.msg              
            }
          })
          .addCase(login.rejected, (state, action) => {
            state.loginLoad = false
            state.loginError = true
            state.errorMessage = 'Unable to login'
          })

          // signup
          .addCase(signup.pending, (state, action) => {
            state.signupLoad = true
            state.signupError = false
          })
          .addCase(signup.fulfilled, (state, action) => {
            state.signupLoad = true
        
            const { status, code, response } = action.payload
            if (code === 500) {
              state.signupLoad = false
              state.signupError = true
              state.errorMessage = `Can't login due to network`

            } else if (status === 'success') {
              state.signupError = false
              state.signupLoad = false

            } else {
              state.signupLoad = false
              state.signupError = true
              state.errorMessage = response.msg 
            }
          })
          .addCase(signup.rejected, (state, action) => {
            state.signupLoad = false
            state.signupError = true
            state.errorMessage = 'Unable to signup'
          })

          // reset password 

          .addCase(resetPassword.pending, (state, action) => {
            state.resetPasswordLoad = true
            state.resetPasswordError = false
            state.resetPasswordSent = false
          })
          .addCase(resetPassword.fulfilled, (state, action) => {
            state.resetPasswordLoad = true
            const { status, code, response } = action.payload
            if (code === 500) {
              state.resetPasswordLoad = false
              state.resetPasswordError = true
              state.errorMessage = `Bad connection`

            } else if (status === 'success') {
              state.resetPasswordLoad = false
              state.resetPasswordError = false
              state.resetPasswordSent = true
              state.passwordResetMessage = response.msg

            } else {
              state.resetPasswordLoad = false
              state.resetPasswordError = true
              state.errorMessage = response.msg 
            }
          })
          .addCase(resetPassword.rejected, (state, action) => {
            state.resetPasswordLoad = false
            state.resetPasswordError = true
            state.errorMessage = 'Unable to reset password'
          })

          // change password 
          .addCase(changePassword.pending, (state, action) => {
            state.changePasswordLoad = true
            state.changePasswordError = false
          })
          .addCase(changePassword.fulfilled, (state, action) => {
            state.changePasswordLoad = true
            const { status, code, response } = action.payload
            if (code === 500) {
              state.changePasswordLoad = false
              state.changePasswordError = true
              state.errorMessage = `Bad connection`

            } else if (status === 'success') {
              state.changePasswordLoad = false
              state.changePasswordError = false

            } else {
              state.changePasswordLoad = false
              state.changePasswordError = true
              state.errorMessage = response.msg 
            }
          })
          .addCase(changePassword.rejected, (state, action) => {
            state.changePasswordLoad = false
            state.changePasswordError = true
            state.errorMessage = 'Unable to update password'
          }) 

          // logout 
          .addCase(logout.pending, (state, action) => {
            state.logoutLoad = true
            state.logoutError = false
          })
          .addCase(logout.fulfilled, (state, action) => {
            state.logoutLoad = true
            const { status, code, response } = action.payload
            if (code === 500) {
              state.logoutLoad = false
              state.logoutError = true
              state.logoutErrorMsg = `Bad connection`

            } else if (status === 'success') {
              state.loginLoad = false
              state.logoutError = false
              window.location.href = '/'

            } else {
              state.logoutLoad = false
              state.logoutError = true
              state.logoutErrorMsg = response.msg 
            }
          })
          .addCase(logout.rejected, (state, action) => {
            state.logoutLoad = false
            state.logoutError = true
            state.logoutErrorMsg = 'Unable to update password'
          }) 

    }
})

export default authSlice.reducer
export const {
  loadingState,
  fillAuthInputs
} = authSlice.actions